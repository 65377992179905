.container {
    width: 100%;
    padding: 24px;
}

.code-link {
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
}

.open-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
}

.code-input-wrap {
    flex-grow: 2;
    flex-shrink: 0;
}

.code-input {
    height: 2rem;
    width: 100%;
    padding: 0rem .5rem;
    border: none;
    margin-bottom: 1rem;
    background-color: rgb(33 33 39);
    height: 44px;
    border-radius: 0px;
    color: white;
    font-family: 'Space-Mono';
    text-transform: uppercase;
    font-size: 12px;

    ::-webkit-input-placeholder {
      opacity: 1 !important;
    }

    &:focus{
        border: 1px solid purple;
        outline: none;

    }
    &:active {
        border: none;
    }
}

.submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: min-content;
    background-color: rgb(33 33 39);
    height: 44px;
    cursor: pointer;
    border-radius: 0px;
    text-decoration: underline;
}
