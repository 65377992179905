@font-face {
  font-family: 'Space-Grotesk';
  src: local('Space-Grotesk'), url(./assets/fonts/SpaceGrotesk-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Space-Mono';
  src: local('Space-Mono'), url(./assets/fonts/SpaceMono-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1E1E1E;
  font-family:'Space-Grotesk', Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #EFFBFF;
}

code {
  font-family: 'Space-Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  text-decoration: none;
  color: #E6F341;
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.root {
  width: 100vw;
}